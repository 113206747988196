import axios from 'axios'
import token from '@/utils/auth'
import { ElNotification } from 'element-plus'
import apiUrl from './apiUrl'
import store from './store'


let wyEnv = token.getSearchParam('wy_env') || 'sn'
// if (wyEnv.substr(-1) === '/') {
//   wyEnv = wyEnv.slice(0, wyEnv.length - 1)

// }


let api
if (window.location.hostname.indexOf('test.auth.wenyanmedia.com') !== -1) {
  // 测试
  api = wyEnv === 'sn' ? apiUrl.dev : wyEnv === 'sw' ? apiUrl.devsw : apiUrl.devnew

} else {
  // 正式
  api = wyEnv === 'sn' ? apiUrl.master : wyEnv === 'sw' ? apiUrl.mastersw : apiUrl.masternew
}




let userAuthListAPI = api.userAuthListAPI // 查询用户授权列表
let ksclientAPI = api.ksclientAPI // 查询用户授权列表
// eslint-disable-next-line no-unused-vars
const operationApi = api.xyxNewDataApi;


axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'


/**
* 操作行为上报
* @param {*} response 接口响应体
* @returns
*/

const operationPost = (response) => {
  console.log('response', response);


  // 上报接口不在上报, 防止循环
  const API = api.xyxNewDataApi;
  const operationAPI = `${API}/background/operation/report/`;
  if (response.config.url === operationAPI) {
    return;
  }
  let parameter;
  if (!response.config.params && response.config.data === undefined) {
    parameter = '无';
  } else {
    parameter = (response.config.params && response.config.data === undefined) ?
      response.config.params : response.config.data;
  }
  const href = window.location.hash.split('/');
  let gameId = 99;
  href.forEach((item) => {
    if (!isNaN(Number(item))) {
      gameId = Number(item);
    }
  });

  const data = {
    game_id: gameId || 99,
    operation: response.config.method,
    url: window.location.href,
    api: response.config.url,
    features: {
      platform: 'wechatmgrapp',
      api_name: '',
      page_name: '',
      body: parameter,
    },
  };
  console.log('data', data);

  axios.post(`${operationApi}/background/operation/report/`, data)
    .then((res) => {
      console.log('上报成功之后', res.data.message);
    });
};


const envTypeLoginInfo = {
  1: 'http://login.xq5.com',
  2: 'http://login.xq556.com',
  3: 'http://login.xq668.com'
}

let url
if (window.location.hash !== '#/noPermission') {
  url = window.btoa(encodeURIComponent(window.location.href))
} else {
  // 排除 是无权限页面的情况
  url = window.btoa(encodeURIComponent(window.location.origin))
}

// 如果用application/x-www-form-urlencoded，下面的数据需要使用qs.stringify(data)序列化参数
// 如果用application/json，把qs.stringify去掉
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 直接从localStorage获取token
// axios.defaults.headers.common['x-xq5-jwt'] = window.localStorage.getItem('token')
//   ? window.localStorage.getItem('token')
//   : '';
// axios.defaults.headers.common['x-xq5-jwt'] = token.get() ? token.get() : ''
// 请求拦截器
axios.interceptors.request.use((config) => {
  config.headers['x-xq5-jwt'] = token.get() ? token.get() : ''
  return config
}, (error) => {
  ElNotification.error({
    title: '错误',
    message: `${error.config.url}请求失败，请检查参数是否正确。原因${error}`,
    duration: 6000
  })
  return Promise.resolve(error)
})

// 响应拦截器
axios
  .interceptors
  .response
  .use((response) => {

    // 正式环境和测试环境上报, 本地环境不上报
    // 上报接口不弹错误弹窗
    if (response.config.url !== `${operationApi}/background/operation/report/`) {
      if (window.location.hostname.includes('wenyanmedia')) {
        console.log('当前为不是本地环境, 上报行为信息!');
        operationPost(response);
      }
      if (parseInt(response.data.errno, 10) !== 0) {
        ElNotification.error({
          title: '错误',
          message: response.data.errmsg,
          duration: 5000
        })
      }
    }

    return response
  }, (error) => {
    if (error.config.url === `${operationApi}/background/operation/report/`) {
      return Promise.resolve(error);
    }

    if (!error.response) {
      ElNotification.error({
        title: '错误',
        message: `${error.config ? error.config.url : error}响应失败，请刷新浏览器重试。原因${error}`,
        duration: 6000
      })
    } else {
      switch (error.response.status) {
        case 401:
          ElNotification.error({
            title: '错误',
            message: '登录信息过期，请退出至登陆页401',
            duration: 5000
          })
          token.remove()
          window.location = `${envTypeLoginInfo[store.state.APIType]}/?request_url=${url}&token_in_url=1`
          break
        case 403:
          ElNotification.error({
            title: '错误',
            message: '您没有权限进行此操作403',
            duration: 5000
          })
          break
        case 404:
          ElNotification.error({
            title: '错误',
            message: '接口网址未找到404',
            duration: 5000
          })
          break
        default:
          ElNotification.error({
            title: '错误',
            message: '未知错误',
            duration: 5000
          })
          return Promise.reject(error)
      }
    }
    return error
  })
export default {
  // 获取用户信息
  getUserInfo(data) {
    return axios.post(`${ksclientAPI}/web/v1/ks/permission`, data)
  },
  // 权限接口
  authpermissionAPI(data) {
    return axios.post(`${userAuthListAPI}/wy/user/authpermission`, data)
  },
  // 查询用户授权列表
  getUserAuthList(data) {
    // return axios.post(`${userAuthListAPI}/v1/permission/userauthlist`, data)
    return axios.post(`${userAuthListAPI}/wy/permission/userauthlist`, data)
  },
  // 删除用户某一资源下的授权
  permissionDelete(data) {
    return axios.post(`${userAuthListAPI}/wy/permission/delete`, data)
  },
  // 保存用户的授权
  permissionAdd(data) {
    return axios.post(`${userAuthListAPI}/wy/permission/add`, data)
  },
  // 编辑用户权限接口
  editPermission(data) {
    return axios.post(`${userAuthListAPI}/wy/permission/edit`, data)
  },
  // 查询用户授权列表新接口
  // getNewUserAuthList(data) {
  //   return axios.post(`${userAuthListAPI}/v1/permission/new/userauthlist`, data)
  // },

  // 查询资源列表
  configresourcedataList(data) {
    return axios.post(`${userAuthListAPI}/wy/resource/list`, data)
  },
  // 添加资源
  configresourcedataAdd(data) {
    return axios.post(`${userAuthListAPI}/wy/resource/add`, data)
  },
  // 更新资源
  configresourcedataUpdata(data) {
    return axios.post(`${userAuthListAPI}/wy/resource/update`, data)
  },
  // 删除元数据-主要用于删除某一游戏平台下的元数据
  configresourcedataDelete(data) {
    return axios.post(`${userAuthListAPI}/wy/resource/delete`, data)
  },

  operationlog(data) {
    return axios.post(`${userAuthListAPI}/v1/operationlog/page`, data)
  },
  // 效验token是否失效
  verifyTokenExpire() {
    return axios.get(`${userAuthListAPI}/v1/verify/token/expire`)
  }
}
