/**
 * token 相关存取操作
 */

const tokenKey = 'token'

const get = function () {
  return window.localStorage.getItem(tokenKey)
}

const set = function (token) {
  window.localStorage.setItem(tokenKey, token)
}

const remove = function () {
  window.localStorage.removeItem(tokenKey)
}

const getSearchParam = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) {
    if (r[2].substr(-1) === '/') {
      r[2] = r[2].slice(0, r[2].length - 1)

    }
    return decodeURIComponent(r[2])
  }
  return null
}

export default { get, set, remove, getSearchParam }
