const master = {
  // 余凯
  // 测试服务器IP及端口：http://47.93.185.30:9031
  // 查询用户授权列表
  userAuthListAPI: 'https://authority.xq5.com', // http://47.93.185.30:9031
  ksclientAPI: 'https://ksclientapi.xq5.com',
  xyxNewDataApi: 'https://xyxdata.xq5.com',
}
const dev = {
  // 余凯
  // 测试服务器IP及端口：http://47.93.185.30:9031
  // 添加资源
  // 查询用户授权列表
  userAuthListAPI: 'http://authorityapisrv.test.xq5.com',
  ksclientAPI: 'http://123.206.215.185:9140',
  xyxNewDataApi: 'http://129.211.113.36:4326',
}
const masternew = {
  // 余凯
  // 测试服务器IP及端口：http://47.93.185.30:9031
  // 查询用户授权列表
  userAuthListAPI: 'https://authority.xq556.com',
  ksclientAPI: 'https://ksclientapi.xq556.com',
  xyxNewDataApi: 'https://xyxdata.xq5.com',
}
const devnew = {
  // 余凯
  // 测试服务器IP及端口：http://47.93.185.30:9031
  // 添加资源
  // 查询用户授权列表
  userAuthListAPI: 'https://authority.xq556.com',
  ksclientAPI: 'https://ksclientapi.xq556.com',
  xyxNewDataApi: 'http://129.211.113.36:4326',
}
const mastersw = {
  // 余凯
  // 测试服务器IP及端口：http://47.93.185.30:9031
  // 查询用户授权列表
  userAuthListAPI: 'https://authority.xq668.com',
  ksclientAPI: 'https://ksclientapi.xq668.com',
  xyxNewDataApi: 'https://xyxdata.xq5.com',
}
const devsw = {
  // 余凯
  // 测试服务器IP及端口：http://47.93.185.30:9031
  // 添加资源
  // 查询用户授权列表
  userAuthListAPI: 'https://authority.xq668.com',
  ksclientAPI: 'https://ksclientapi.xq668.com',
  xyxNewDataApi: 'http://129.211.113.36:4326',
}
module.exports = {master, dev, masternew, devnew, mastersw, devsw}
