import Vuex from 'vuex'
import api from '../api'

// 返回的：user_list 用户列表
const store = new Vuex.Store({
  state: {
    user_info: {},
    user_list: [],
    user_list_obj: {},
    resource_list: [],

    zonst_id: '', // 用户工号
    real_name: '', // 用户姓名
    // 用户拥有的权限（需要前端遍历，又资源名称-游戏名称-身份名称组成一行）
    // 资源列表
    APIType: '初始化当前环境'
  },
  mutations: {
    getAPITypeInVuex (state, num) {
      state.APIType = num
    },
    getUserList (state, res) {
      const newState = state
      const data = res.data.data
      data.forEach((item) => {
        const obj = item
        obj.exist = true
        newState.user_list_obj[item.zonst_id] = obj
      })
      newState.user_list = res.data.data.sort(
        (a, b) => a.zonst_id - b.zonst_id
      )
    },
    getUserInfo (state, res) {
      state.user_info = res.data.data
    }
    /* getCurrId (state, res) {
      const newState = state
      newState.currid = res.id
    } */
  },
  actions: {
    getUserAuthList ({ commit }) {
      return new Promise((resolve) => {
        api.getUserAuthList().then((response) => {
          commit({
            type: 'getUserAuthList',
            data: response.data
          })
          resolve(response)
        })
      })
    },
    getUserInfo ({ commit }, data) {
      commit({
        type: 'getUserInfo',
        data: data
      })
    }
  }
})
export default store
