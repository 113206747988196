import { createApp } from 'vue';
import axios from 'axios';
import ElementUI from 'element-plus';
import * as ElementPlusIcon from '@element-plus/icons-vue';
import { injectionParams } from '@zonst/apm';
import 'element-plus/theme-chalk/index.css';
import './assets/css/common.css';
import App from './App';
import router from './router';
import api from './api';
import store from './store';
import auth from '@/utils/auth'
import 'dayjs/locale/zh-cn' //中文
import  locale from 'element-plus/lib/locale/lang/zh-cn' // 中文

console.log('process.env.NODE_ENV',process.env.NODE_ENV);


injectionParams(() => ({
  env: process.env.NODE_ENV.includes('development') ? 'test' : 'prod',
  journalType: 1,
  platformKey: 'wyauthapisrv',
  token: window.localStorage.getItem('token'),
}));

const toDate = (hasSeperator, date) => {
  const nowDate = date;
  const y = nowDate.getFullYear();
  let m = nowDate.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d = nowDate.getDate();
  d = d < 10 ? `0${d}` : d;
  if (hasSeperator) {
    return `${y}-${m}-${d}`;
  }
  return `${y}${m}${d}`;
};
const toDateTime = (hasSeperator, date) => {
  const nowDate = date;
  const y = nowDate.getFullYear();
  let m = nowDate.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d = nowDate.getDate();
  d = d < 10 ? `0${d}` : d;
  let h = nowDate.getHours();
  h = h < 10 ? `0${h}` : h;
  let min = nowDate.getMinutes();
  min = min < 10 ? `0${min}` : min;
  let s = nowDate.getSeconds();
  s = s < 10 ? `0${s}` : s;
  // const s = nowDate.getSeconds()
  if (hasSeperator) {
    return `${y}-${m}-${d} ${h}:${min}:${s}`;
  }
  return `${y}${m}${d}${h}${min}${s}`;
};
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIcon)) {
  app.component(key, component);
}
app.config.productionTip = false;
app.config.globalProperties.axios = axios;
app.config.globalProperties.$api = api;
app.config.globalProperties.$store = store;
app.config.globalProperties.toDateTime = toDateTime;
app.config.globalProperties.toDate = toDate;
app.config.globalProperties.auth = auth;
app.use(ElementUI, {locale});
app.use(router);
app.use(store);
app.mount('#app');

/* eslint-disable no-new */
// createApp({
//   el: '#app',
//   router,
//   store,
//   components: { App },
//   template: '<App/>',
//   methods: {
//     /* 转化时间格式为 2018-05-24 */
//     toDate (hasSeperator, date) {
//       const nowDate = date
//       const y = nowDate.getFullYear()
//       let m = nowDate.getMonth() + 1
//       m = m < 10 ? `0${m}` : m
//       let d = nowDate.getDate()
//       d = d < 10 ? `0${d}` : d
//       if (hasSeperator) {
//         return `${y}-${m}-${d}`
//       }
//       return `${y}${m}${d}`
//     },
//     /* 转化时间为 2018-05-24 17:17:46 格式 */
//     toDateTime (hasSeperator, date) {
//       const nowDate = date
//       const y = nowDate.getFullYear()
//       let m = nowDate.getMonth() + 1
//       m = m < 10 ? `0${m}` : m
//       let d = nowDate.getDate()
//       d = d < 10 ? `0${d}` : d
//       let h = nowDate.getHours()
//       h = h < 10 ? `0${h}` : h
//       let min = nowDate.getMinutes()
//       min = min < 10 ? `0${min}` : min
//       let s = nowDate.getSeconds()
//       s = s < 10 ? `0${s}` : s
//       // const s = nowDate.getSeconds()
//       if (hasSeperator) {
//         return `${y}-${m}-${d} ${h}:${min}:${s}`
//       }
//       return `${y}${m}${d}${h}${min}${s}`
//     }
//   }
// }).mount()
