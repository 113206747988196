/* eslint-disable no-undef */
import { createRouter, createWebHashHistory } from 'vue-router'
import token from '@/utils/auth'
import WaterMark from '@zonst/watermark'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { parseTime } from '../utils/util'
import store from '../store'
import api from '../api'


let wyEnv = token.getSearchParam('wy_env') || 'sn'

function getAPIType() {
  // 1 省内为xq5.com接口 2 鹰潭为xq556接口 3省外为xq668
  if (wyEnv === 'sn') {
    return '1'
  } else if (wyEnv === 'yt') {
    return '2'
  } else if (wyEnv === 'sw') {
    return '3'
  }
  return '无法匹配当前环境'
}

store.commit('getAPITypeInVuex', getAPIType())


const getPermission = (to, from, next) => {
  // axios.post('http://authority.xq5.com/v1/user/authpermission', {
  api.authpermissionAPI({
    token: token.get(),
    game_id: 7
  }).then((res) => {
    if (res && res.data && Number(res.data.errno) === 0) {
      if (!res.data.permission) {
        next({
          path: '/noPermission',
          query: { from: 'notSetPermission' }
        })
        // this.$router.push({ path: '/noPermission', query: { from: 'notSetPermission' } });
      } else {
        console.log('getUserInfo', res.data);

        store.dispatch({
          type: 'getUserInfo',
          data: res.data
        })
        next()
      }
    } else if (!res || !res.data) {
      ElMessage({
        type: 'error',
        message: `登录失效或权限接口出现异常。${res}`
      })
    }

    // if (res.data.permission === 1) {
    //   // beforeEnterAuth(to, from, next)
    //   next()
    // } else {
    //   next('/noPermission')
    // }
  })
}


// 获取用户信息
const beforeEnterGetUserInfo = (to, from, next) => {
  api.getUserInfo().then((res) => {
    if (res && res.data && Number(res.data.errno) === 0) {
      console.log('获取用户信息', res)
      WaterMark.init({
        text: `${res.data.user_info.zonst_user_id}${res.data.user_info.user_name}${parseTime(new Date(), '{y}{m}{d}')}`,
        fontSize: 24,
        secret: true
      })
    }
  })
  next()
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      component: () => import('@/components/Index'),
      redirect: '/qipaiAuthLsit',
      beforeEnter(to, from, next) {
        beforeEnterGetUserInfo(to, from, next)
      },
      children: [
        {
          path: 'qipaiAuthLsit',
          name: 'qipaiAuthLsit',
          component: () => import('@/components/manage'),
          beforeEnter(to, from, next) {
            getPermission(to, from, next)
          }
        },
        {
          path: 'renliAuthList',
          name: 'renliAuthList',
          component: () => import('@/components/renli/renliList'),
          beforeEnter(to, from, next) {
            getPermission(to, from, next)
          }
        },
        {
          path: 'caiwuAuthList',
          name: 'caiwuAuthList',
          component: () => import('@/components/caiwu/CaiwuList'),
          beforeEnter(to, from, next) {
            getPermission(to, from, next)
          }
        },
        // {
        //   path: 'qixuanAuthList',
        //   name: 'qixuanAuthList',
        //   component: () => import('@/components/qixuan/QixuanList'),
        //   beforeEnter (to, from, next) {
        //     getPermission(to, from, next)
        //   }
        // },
        // {
        //   path: 'NadaAuth/NadaAuthList',
        //   name: 'NadaAuthList',
        //   component: () => import('@/components/NadaAuthList'),
        //   beforeEnter (to, from, next) {
        //     getPermission(to, from, next)
        //   }
        // },
        {
          path: 'resource',
          name: 'resource',
          component: () => import('@/components/resource'),
          beforeEnter(to, from, next) {
            getPermission(to, from, next)
          }
        },
        // {
        //   path: 'operationLog',
        //   name: 'operationLog',
        //   component: () => import('@/components/operationlog/Log'),
        //   beforeEnter (to, from, next) {
        //     getPermission(to, from, next)
        //   }
        // },
        {
          path: '/noPermission',
          name: 'noPermission',
          component: () => import('@/components/noPermission')
        }
      ]
    },
    {
      path: '/404',
      name: '/404',
      component: () => import('@/components/notFind')
    },
    {
      path: '/:pathMatch(.*)',
      redirect: '/404'
    },
  ]
})

function getRemovedTokenInUrl() {
  // 'http://10.0.0.26:8082/?tdsourcetag=s_pctim_aiomsg&token=2VyX2lkIjo0MzgsInVzZXJfbmFtZSI6IuadjuW9qSIsImdyb3VwX2lkIjoyLCJpc19zdXBlcnVzZXIiOmZhbHNlLCJleHAiOjE2MjQ4NjExMDN9.bbSiv8c3T_GiWpJ4TGDn6qHOE2JvlvfsBod01G_X2sU#/gamelist'
  // http://10.0.0.26:8082/?token=X2lkIjo0MzgsInVzZXJfbmFtZSI6IuadjuW9qSIsImdyb3VwX2lkIjoyLCJpc19zdXBlcnVzZXIiOmZhbHNlLCJleHAiOjE2MjQ4NjI3NTF9.ALSMn-0qjKa2zhaRdnY_zWgXAvqL-mzK64pFLNBc-s8#/gamelist
  const removeTokenArray = window.location.href.split('token=')
  const frontPart = removeTokenArray[0].slice(0, removeTokenArray[0].length - 1)
  const hashPart = removeTokenArray[1].split('#')[1]
  console.log('url', `${frontPart}#${hashPart}`)
  return `${frontPart}#${hashPart}`
}

router.beforeEach((to, from, next) => {
  // if (window.location.search !== '') {
  //   Cookie.set('token', getSearchParam('token'))
  //   localStorage.setItem('token', getSearchParam('token'))
  // } else {
  //   Cookie.get('token')
  // }
  // // if (process.env.NODE_ENV === 'development') {
  // //   next();
  // //   return;
  // // }
  //
  // if (Cookie.get('token')) {
  //   axios.defaults.headers.common['x-xq5-jwt'] = Cookie.get('token') ? Cookie.get('token') : ''
  //   next()
  // } else if (!Cookie.get('token')) {
  //   const url = window.btoa(encodeURIComponent(window.location.href))
  //   window.location = `http://login.xq5.com?request_url=${url}`
  // }

  try {
    window.localStorage.setItem('preSetLocalStorage', 'yes')
    if (!window.localStorage.getItem('preSetLocalStorage')) {
      alert('监测到浏览器无法跨站存储信息,请关闭阻止第三方cookie或关闭跨站跟踪或关闭无痕模式L')
    }
  } catch (error) {
    alert(`监测到浏览器无法跨站存储信息,请关闭阻止第三方cookie或关闭跨站跟踪或关闭无痕模式L。${err}`)
  }

  if (window.location.search !== '' && token.getSearchParam('token')) {
    token.set(token.getSearchParam('token'))

    axios.defaults.headers.common['x-xq5-jwt'] = token.get() ? token.get() : ''
    // alert(`${window.location.href} 头部写入了token----------   ${window.localStorage.getItem('tokenall')}+++++++++++++++++++++tokenTemp`);
    window.history.replaceState({}, document.title, getRemovedTokenInUrl())
  }

  if (!token.get()) {
    let url
    if (window.location.hash !== '#/noPermission') {
      url = window.btoa(encodeURIComponent(window.location.href))
    } else {
      // 排除 是无权限页面的情况
      url = window.btoa(encodeURIComponent(window.location.origin))
    }

    const envTypeLoginInfo = {
      1: 'http://login.xq5.com',
      2: 'http://login.xq556.com',
      3: 'http://login.xq668.com'
    }
    window.location = `${envTypeLoginInfo[store.state.APIType]}/?request_url=${url}&token_in_url=1`
    // const prefixLoginStr = Number(getAPIType()) === 1 ? 'xq5' : Number(getAPIType()) === 2 ? 'xq556' : 'xq668'
    // window.location = `http://login.${prefixLoginStr}.com/?request_url=${url}`
  } else {
    next()
  }
})

//   if (Cookie.get('token')) {
//     next()
//   } else {
//     setTimeout(() => {
//       // if (!Cookie.get('token')) {
//       const url = window.btoa(encodeURIComponent(window.location.href))
//       window.location = `http://login.xq5.com/?request_url=${url}`
//       next()
//       // } else {
//       //   next()
//       // }
//     }, 500)
//   }
// })

export default router
